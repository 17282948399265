
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';



function EditStudentName({accessToken,setAccessToken,setRefreshFailed,setIsAppLoaded}) {
  const [newName,setNewName]=useState('');
  const [rollno,setRollno]=useState('');
  const [name,setName]=useState('');
   const onSave=async ()=>{
    try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/updateStudentName',{rollno:rollno,name:newName},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        alert(JSON.stringify( response.data)); 
        //setSelectedCourses(response.data.data);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="admin")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/updateStudentName',{rollno:rollno,name:newName},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        alert(JSON.stringify( response.data)); 
                        //setSelectedCourses(response.data.data);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      alert('refresh failed unusual'+ "line 50");
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
   }
   const onFocusLost=async ()=>{
    try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/getStudentDetails',{rollno:rollno},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify( response.data)); 
        setName(response.data.student.name);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="admin")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/getStudentDetails',{rollno:rollno},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify( response.data)); 
                        setName(response.data.student.name);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      alert('Student Not Found');
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
   }
   
    return (
      <main>
        <div className="fullWidth centeredDiv verticaldiv">
            <h3>EDIT STUDENT DETAILS</h3>
            <label className="marginTop" htmlFor='txtFromTime'>ROLLNO:</label>
                <input 
                type='text' className="borderLess"
                id='txtFromTime'
                required
                value={rollno}
                onChange={(e)=>{setRollno(e.target.value)}}
                onBlur={onFocusLost}
                />
            <label className="marginTop" htmlFor='txtFromTime'>Existing Name:</label>
            <label>{name}</label>
        <label className="marginTop" htmlFor='txtFromTime'>New Name:</label>
                <input 
                type='text' className="borderLess"
                id='txtFromTime'
                required
                value={newName}
                onChange={(e)=>{setNewName(e.target.value)}}
                />
        <div className="secondaryNav marginTop">
            <button className='btn btn-primary' style={{fontSize:'13px', letterSpacing:'1.1px'}} onClick={onSave}>SAVE</button>
        </div>

        </div>
      </main>
    )
  }
  
  export default EditStudentName