
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';
import OfferedCoursesS from './OfferedCoursesS';
import SelectedCourseList from './SelectedCourseList';
import PaymentDetailsUpload from './PaymentDetailsUpload';
import UploadedTransactions from './UploadedTransactions';
import FormFillupDownloadPDF from './FormFillupDownloadPDF';
import EditTransactionDetails from './EditTransactionDetails';

function StudentExamFormFillup({accessToken,setAccessToken,setRefreshFailed}) {
  const [selectedSemester,setSelectedSemester]=useState(-1);
  const [isAppLoaded,setIsAppLoaded]=useState(true);
  const [loggedInStudent,setLoggedInStudent]=useState(null);
  const [semOfferedCourses,setSemOfferedCourses]=useState(null);  
  const [selectedCourses,setSelectedCourses]=useState(null);
  const [mode,setMode]=useState('view');
  const [isCourseSelected,setIsCourseSelected]=useState(false);//isCourseSelected isPayementUploaded
  const [isPayementUploaded,setIsPayementUploaded]=useState(false);
  const [currentTransactionId,setCurrentTransactionId]=useState();
  const session='2024-ODD';

  const loadOfferedCourses=async ()=>{
   //alert(JSON.stringify(loggedInStudent)+" selected sem "+selectedSemester);
    try{       
      //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
      const response=await api.post('/getOfferedCourses',{program:loggedInStudent.programme_code,specialisation:loggedInStudent.MajorSub,semester:selectedSemester},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
      //alert("load courses "+JSON.stringify(response.data));
      setSemOfferedCourses(response.data);
      //alert(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);            
          const nat=x['data']['accessToken'];
          const ufn=x['data']['userFullName'];
          const un=x['data']['userName'];
          const lt=x['data']['loginType'];
          //alert(nat);
          setAccessToken(nat);
          setRefreshFailed(false);  
                  
         
            if(lt.toLowerCase()==="student")
              {
                    try{       
                      //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                      const response=await api.post('/getOfferedCourses',{program:loggedInStudent.programme_code,specialisation:loggedInStudent.MajorSub,semester:selectedSemester},{headers:{'Authorization':`Bearer ${nat}`}});                            
                      //alert("load courses "+JSON.stringify(response.data));
                      setSemOfferedCourses(response.data);
                      //alert(response.data);
                  }
                  catch(err)
                  {
                    alert('refresh failed unusual'+ "line 50");
                    setAccessToken('');
                    setRefreshFailed(true);  
                  }
                }
          }
          catch(err)
          {
            //alert('dd');
            setAccessToken(''); 
            setRefreshFailed(true);              
          }
      }         
       finally
          {
            setIsAppLoaded(true);
            //alert('app loaded'+accessToken);
          }
  }

  const loadSelectedCourses=async ()=>{
    //alert(JSON.stringify(loggedInStudent)+" selected sem "+selectedSemester);
     try{       
       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
       const response=await api.post('/getSemesterSelectedCourses',{semester:selectedSemester,session:session},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
       //alert(JSON.stringify( response.data.data)); 
       setSelectedCourses(response.data.data);
       //alert(response.data);
       }
       catch(err)
       {
         try{
           const config = { withCredentials: true };
           const x=await loginapi.get('/getAccessToken',config);            
           const nat=x['data']['accessToken'];
           const ufn=x['data']['userFullName'];
           const un=x['data']['userName'];
           const lt=x['data']['loginType'];
           //alert(nat);
           setAccessToken(nat);
           setRefreshFailed(false);  
                   
          
             if(lt.toLowerCase()==="student")
               {
                     try{       
                       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                       const response=await api.post('/getSemesterSelectedCourses',{semester:selectedSemester,session:session},{headers:{'Authorization':`Bearer ${nat}`}});                            
                       //alert(JSON.stringify(response.data.data)); 
                       setSelectedCourses(response.data.data);
                       //alert(response.data);
                   }
                   catch(err)
                   {
                     alert('refresh failed unusual'+ "line 50");
                     setAccessToken('');
                     setRefreshFailed(true);  
                   }
                 }
           }
           catch(err)
           {
             //alert('dd');
             setAccessToken(''); 
             setRefreshFailed(true);              
           }
       }         
        finally
           {
             setIsAppLoaded(true);
             //alert('app loaded'+accessToken);
           }
  }
  function onShowSelectedList()
  {
    //alert(mode);
    setMode('view');
  }
  
  useEffect( ()=>{
    const fetchAT=async ()=>{

      try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/getLoggedInStudentDetails',{id:1},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify(response.data));
        setLoggedInStudent(response.data.student);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="student")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/getLoggedInStudentDetails',{id:1},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify(response.data));
                        setLoggedInStudent(response.data.student);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      alert('refresh failed unusual'+ "line 105");
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
    }
    fetchAT();
  },[]);

  useEffect( ()=>{
    if(loggedInStudent)
    {
      loadOfferedCourses();
      loadSelectedCourses();
    }
  },[selectedSemester]);
  
  const onSemClick = async (event) => {
    setSelectedSemester(event.target.value);
    //retrieve offered courses for the prog specialisation and sem     
  };

  function getSelectSemesterPanel()
  {
    const noofsem=8;
    const semesters =Array.from({ length: noofsem }, (_, index) => ({
      id: index + 1,
      text: `sem${index + 1}`
    }));
    return (
      <div className="semcontainer marginTopm">
        {selectedSemester<0 && <p>Please select a Semester</p>}
        <div className="album">
          {semesters.map(item => (
            <button
              value={item.id}
              key={item.id}
              className={ item.id==selectedSemester?'circlebutton circularbuttonactive':'circlebutton circularbuttoninactive'}
              onClick={(e)=>{onSemClick(e)}}
            >
              {item.text}
            </button>
          ))}
        </div>
      </div>
    );
  }
  
  function getOfferedCourses()
  {
    return (
      <div className="verticaldiv centrediv fullWidth marginTopm">
         <OfferedCoursesS accessToken={accessToken} 
                setAccessToken={setAccessToken}
                setRefreshFailed={setRefreshFailed} session={session} data={semOfferedCourses} setSelectedCourses={setSelectedCourses} selectedCourses={selectedCourses} semester={selectedSemester} />
      </div>
    );
  }

  let progSpec="";
  if(loggedInStudent)
  {
   progSpec=loggedInStudent.rollno+" "+loggedInStudent.programme_code+","+loggedInStudent.MajorSub;
  }
  return (
      <main>
        <div className="verticaldiv centrediv fullWidth ">
        <div className="fullWidth centeredDiv"><h2>EXAM FORM FILLUP</h2></div>
        <div className="fullWidth centeredDiv"><h5>{loggedInStudent?loggedInStudent.name:'YOU ARE NOT KNOWN!'}</h5></div>
        {loggedInStudent && <div className="fullWidth centeredDiv"><h6>{loggedInStudent?progSpec:''}</h6></div>}
        
        { mode==='select' && <div className="fullWidth">
        {loggedInStudent && getSelectSemesterPanel()}
        {loggedInStudent && selectedSemester>0 && getOfferedCourses()}
        <div className="verticaldiv centrediv fullWidth marginBottom">
        {loggedInStudent && selectedSemester>0 &&<button className="btn btn-success marginTopm" onClick={(e)=>{onShowSelectedList(e)}}>DONE</button> }
        {loggedInStudent && selectedSemester<=0 &&<button className="btn btn-success marginTopm marginBottom" onClick={(e)=>{onShowSelectedList(e)}}>GO BACK</button> }
        </div>
        </div>
        }
          {
            mode==='view' && <SelectedCourseList setIsCourseSelected={setIsCourseSelected}   setMode={setMode} session={session} accessToken={accessToken} 
            setAccessToken={setAccessToken}
            setRefreshFailed={setRefreshFailed}></SelectedCourseList> 
          }
          {
            mode==='view' && <UploadedTransactions  setCurrentTransactionId={setCurrentTransactionId} setIsPayementUploaded={setIsPayementUploaded} setMode={setMode} setIsAppLoaded={setIsAppLoaded} session={session} accessToken={accessToken} 
            setAccessToken={setAccessToken}
            setRefreshFailed={setRefreshFailed}></UploadedTransactions> 
          }

          {
            mode==='view' && <FormFillupDownloadPDF
            isCourseSelected={isCourseSelected} isPayementUploaded={isPayementUploaded}
            isAppLoaded={isAppLoaded} setIsAppLoaded={setIsAppLoaded} mode={mode} setMode={setMode} session={session} accessToken={accessToken} 
            setAccessToken={setAccessToken}
            setRefreshFailed={setRefreshFailed}
            >

            </FormFillupDownloadPDF>
          }
          
          {
           mode==='UPLOADPAYMENTNEW' && <PaymentDetailsUpload isAppLoaded={isAppLoaded} setIsAppLoaded={setIsAppLoaded} mode={mode} setMode={setMode} session={session} accessToken={accessToken} 
           setAccessToken={setAccessToken}
           setRefreshFailed={setRefreshFailed}
           ></PaymentDetailsUpload>            
          }
          {
           mode==='editTransactionDetails' && <EditTransactionDetails currentTransactionId={currentTransactionId} setCurrentTransactionId={setCurrentTransactionId} isAppLoaded={isAppLoaded} setIsAppLoaded={setIsAppLoaded} mode={mode} setMode={setMode} session={session} accessToken={accessToken} 
           setAccessToken={setAccessToken}
           setRefreshFailed={setRefreshFailed}
           ></EditTransactionDetails>            
          }

        {
        !isAppLoaded &&
          <div className='spinneroverlay'>
            <div className='spinnerbody'>
              <Spinner animation="border" variant="info" />
              <p>Please wait. It might take a few seconds.</p>
            </div>           
          </div>
        }
        </div>
      </main>
    )
  }
  
  export default StudentExamFormFillup