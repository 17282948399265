import React from 'react';
import {useState,useEffect} from 'react';
import api from './api/posts';
import loginapi from './api/login';
import { APISERVERURL } from './APIServerURL';

const FormFillupDownloadPDF = ({isCourseSelected,isPayementUploaded,isAppLoaded,setIsAppLoaded,session,accessToken,setAccessToken,setRefreshFailed}) => {
    function downloadFile(fname)
    {
      fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
        response.blob().then((blob) => {         
            // Creating new object of PDF file
            const fileURL =
                window.URL.createObjectURL(blob);
                 
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            alink.download = fname;
            alink.click();
            setIsAppLoaded(true);
        });
      });
    }
 const getPdf=async (e)=>{
    e.preventDefault();
    try{
      //alert(img+" "+postId);
      //post request to deletePhoto wit {id,fileName}
      setIsAppLoaded(false);
      const response= await api.post('/getFormFillupPdf',{session:session},{headers:{'Authorization':`Bearer ${accessToken}`}});    
      alert(response.data);
      downloadFile(response.data);
    }
    catch(err)
    {
      try{
        const config = { withCredentials: true };
        const x=await loginapi.get('/getAccessToken',config);
        //alert(JSON.stringify(x['data']['accessToken']));
        
        const nat=x['data']['accessToken'];
        setAccessToken(nat);
        //console.log(`ne at ${nat}`);
        //console.log(`Second at:${accessToken}`);
        //alert('ok');
        try{       
          const response= await api.post('/getFormFillupPdf',{session:session},{headers:{'Authorization':`Bearer ${nat}`}});    
          //alert(JSON.stringify(response.data));
          downloadFile(response.data);
         }
         catch(err)
         {
          alert('unusual error newposts.js');
          setIsAppLoaded(true);
          //alert(JSON.stringify(err));
          //navigate('/');
         }
      }
      catch(err)
      {
        alert(JSON.stringify(err));
        setIsAppLoaded(true);
        //setError(JSON.stringify(err));
        //navigate('/');
      }
 
    }
 }
 
  let xx=``;
  if(!isCourseSelected || !isPayementUploaded)
  {
        xx=<p>Your form fillup is not yet completed!. Please Select your courses and Upload your payment receipts to complete your form fillup process.</p>;
  }
  else{
        xx=<button onClick={getPdf} className="btn btn-success">Download PDF</button>;
  }
  return (
    <div className='verticalDiv centeredDiv width1000 marginTop paymentdetailsdiv paddiv'>
      {xx}
    </div>
      
  );

};

export default FormFillupDownloadPDF;
