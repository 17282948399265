import React from 'react';
import {useState,useEffect} from 'react';
import api from './api/posts';
import loginapi from './api/login';
import Transaction from './Transaction';
import SelectedImages from './SelectedImages'
import { APISERVERURL } from './APIServerURL';

const EditTransactionDetails = ({currentTransactionId,setCurrentTransactionId,setIsPayementUploaded,isAppLoaded,setIsAppLoaded,mode,setMode,session,accessToken,setAccessToken,setRefreshFailed}) => {
  const [images,setImages]=useState([]);
  const onGoBackClick=()=>{
    setMode("view");
    setCurrentTransactionId(null);
  }
  const onUpdateClick=async (e)=>{
    let formData = new FormData(); 
    formData.append("id",currentTransactionId._id);
    Object.values(images).forEach(file=>{
      formData.append("images", file);
    });
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }

    try{
      setIsAppLoaded(false);
      const response= await api.post('/updateFormFillupTransactionRecord',formData,{headers:{'Authorization':`Bearer ${accessToken}`}});              
      setIsAppLoaded(true);
      setRefreshFailed(false);
      if(response.data.changed)
        { 
            setCurrentTransactionId(response.data.transaction);
            setImages([]);
        }  
      //alert(JSON.stringify(response.data));
      //setMode('view');
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          setRefreshFailed(false);  
         
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          try{       
            const response= await api.post('/updateFormFillupTransactionRecord',formData,{headers:{'Authorization':`Bearer ${nat}`}});      
            setIsAppLoaded(true);
            if(response.data.changed)
                { 
                    setCurrentTransactionId(response.data.transaction);
                    setImages([]);
                }
           }
           catch(err)
           {
            alert('unusual error newposts.js');
            setIsAppLoaded(true);
            setRefreshFailed(true);  
            //navigate('/');
           }
        }
        catch(err)
        {
          alert('failed');
          setIsAppLoaded(true);
          setRefreshFailed(true);  
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }
  }

  const onDeleteClick=async ()=>{
    //setMode("view");
    //setCurrentTransactionId(null);
    try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/deleteBankTransaction',{id:currentTransactionId._id},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify(response.data));
        setMode("view");
        setCurrentTransactionId(null);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="student")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/deleteBankTransaction',{id:currentTransactionId._id},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify(response.data));
                        setMode("view");
                        setCurrentTransactionId(null);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      alert('refresh failed unusual'+ "line 105");
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
  }
  
  
  function addToImageList(selectedFiles)
  {
    const newImages=[...images,...selectedFiles];
    /*for(let i=0;i<selectedFiles.length;i++)
      {
        let j=0;
        for(j=0;j<images.length;j++)
          {
              if(images[j].name===selectedFiles[i].name)
                {
                  console.log(selectedFiles[i].name);
                  break;
                }
          }
        if(j===images.length)
          {
            newImages.push(selectedFiles[i]);
          }        
      }
          */
      setImages(newImages);
      console.log(newImages.length);
  }
  let xx=`hello ${JSON.stringify(currentTransactionId)}`;
  let uimges=``;
  if(currentTransactionId && currentTransactionId.photos && currentTransactionId.photos.length!==0)
    {
        uimges=currentTransactionId.photos.map((image,index)=>{
        return      <div className='imgThumbHome2' key={index}>
                      <img  src={`${APISERVERURL}/img/${image}`} alt="placeholder" />
                      </div>
       })
    }
    else{
        uimges=<p>
        {"0 photos"}
        </p>
    }
  return (
    
    <div className='width1000 borderdiv paddiv'>
    <div className='verticaldiv centrediv marginTop'><h4>EDIT PAYMENT DETAILS</h4></div>
    <div className='marginTopm'>
          <label htmlFor="txtBankTxNumber">Bank Transaction Number:</label>
          <label id="txtBankTxNumber" className="borderLess transparent" >{currentTransactionId.transactionNumber}</label>            
    </div>
    <div className='marginTopm'>
          <label htmlFor="txtAmount">Amount Paid:</label>
          <label id="txtAmount" className="borderLess transparent" >{currentTransactionId.amount}</label>            
    </div>
    <div className='marginTopm'>
          <label htmlFor="txtAmount">For Semester:</label>
          <label id="txtAmount" className="borderLess transparent" >{currentTransactionId.forSemester}</label>            
    </div>
    <div className='marginTopm'>
        <p>
          Uploaded Photos 
        </p> 
        <div className='album'>
          {uimges}
        </div>
    </div>  
    <div className='marginTopm'>
          <label htmlFor="fuPhotos" className="custom-file-label">
            Select more bank receipt 
          </label>
          <input
            className="file-input-hidden"
            type="file"
            id="fuPhotos"
            name="uploadedPhotos"
            multiple
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => {
              addToImageList(e.target.files);
            }}
          />
      </div>
      <SelectedImages
      images={images}
      setImages={setImages}
      >
      </SelectedImages>  
    <div className='actiondiv  marginTopm'>
         <button className='btn btn-success loginButtonsmall' onClick={onGoBackClick}>GO BACK</button>
         <button className='btn btn-primary loginButtonsmall' onClick={onUpdateClick}>ADD PHOTOS</button>
         <button className='btn btn-danger loginButtonsmall' onClick={onDeleteClick}>DELETE</button>
    </div>
  </div>
      
  );

};

export default EditTransactionDetails;
