function Header({title}) {
  const image="./bannerfile.png";
  return (
    <header className="header ">
        <div className='centeredDiv imgbanner'>             
        <h1>BHATTADEV UNIVERSITY
        </h1>
        </div>
  </header>
  )
}

export default Header