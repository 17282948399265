
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';
import newStudentApi from './api/newStudentApi';
import OfferedCoursesS from './OfferedCoursesS';
import SelectedImages from './SelectedImages';
import { APISERVERURL } from './APIServerURL';



function NewStudent({accessToken,setAccessToken,setRefreshFailed}) {
 const [username, setUsername] = useState('');
 const [studentName, setStudentName] = useState('');
 const [fatherName, setFatherName] = useState('');
 const [motherName, setMotherName] = useState('');
 const [mobileNumber, setMobileNumber] = useState('');
 const [mediumOfExam, setMediumOfExam] = useState('');
 const [caste, setCaste] = useState('');
 const [gender, setGender] = useState('');
 const [religion, setReligion] = useState('');
 const [programs,setPrograms]=useState([]);
 const [selectedProgram,setSelectedProgram]=useState('');
 const [selectedCollege,setSelectedCollege]=useState('');
 const [selectedSpecialisation,setSelectedSpecialisation]=useState('');
 const [semOfferedCourses,setSemOfferedCourses]=useState(null);  
 const [selectedCourses,setSelectedCourses]=useState(null);
 const [bankTransactionNumber ,setBankTransactionNumber]=useState('');
 const [ amount,setAmount]=useState('');
 const [storedImages, setStoredImages] = useState([]);
 const [images,setImages]=useState([]);
 const [selectedCollegeName,setSelectedCollegeName]=useState('');
 const [selectedProgramName,setSelectedProgramName]=useState('');
 const [pdfError,setPdfError]=useState([]);
 const colleges=[
  {
    code:105,
    title:"Barnagar College, Sorbhog"
  },
  {
    code:102,
    title:"B. H. College, Barpeta"
  },{
    code:701,
    title:"Bhattadev College, Bajali"
    },
    {
        code:106,
        title:"Baosi Banikanta Kakati College (BBK) , Nagaon"
    },
    {
        code:101,
        title:"Madhab Choudhury College (M.C. College), Barpeta"
    }

];


 const loadOfferedCourses=async ()=>{
    //alert(JSON.stringify(loggedInStudent)+" selected sem "+selectedSemester);
     try{       
       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
       const response=await api.post('/getOfferedCourses',{program:selectedProgram,specialisation:selectedSpecialisation,semester:1},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
       //alert("load courses "+JSON.stringify(response.data));
       setSemOfferedCourses(response.data);
       //alert(response.data);
       }
       catch(err)
       {
         try{
           const config = { withCredentials: true };
           const x=await loginapi.get('/getAccessToken',config);            
           const nat=x['data']['accessToken'];
           const ufn=x['data']['userFullName'];
           const un=x['data']['userName'];
           const lt=x['data']['loginType'];
           //alert(nat);
           setAccessToken(nat);
           setRefreshFailed(false);  
                   
          
             if(lt.toLowerCase()==="student")
               {
                     try{       
                       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                       const response=await api.post('/getOfferedCourses',{program:selectedProgram,specialisation:selectedSpecialisation,semester:1},{headers:{'Authorization':`Bearer ${nat}`}});                            
                       //alert("load courses "+JSON.stringify(response.data));
                       setSemOfferedCourses(response.data);
                       //alert(response.data);
                   }
                   catch(err)
                   {
                     alert('refresh failed unusual'+ "line 50");
                     setAccessToken('');
                     setRefreshFailed(true);  
                   }
                 }
           }
           catch(err)
           {
             //alert('dd');
             setAccessToken(''); 
             setRefreshFailed(true);              
           }
       }         
        finally
           {
             ////setIsAppLoaded(true);
             //alert('app loaded'+accessToken);
           }
   }

   
 useEffect( ()=>{
    const fetchAT=async ()=>{

      try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await api.post('/getAllPrograms',{id:1},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify(response.data));
        setPrograms(response.data);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="newstudent")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await api.post('/getAllPrograms',{id:1},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify(response.data));
                        setPrograms(response.data);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      alert('refresh failed unusual'+JSON.stringify(err));
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              ////setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
    }  
    fetchAT();
    //fetchCurrent();
  },[]);


  useEffect( ()=>{      
  const fetchCurrent=async ()=>{

      try{       
        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
        const response=await newStudentApi.post('/getCurrentRegistration',{id:1},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
        //alert(JSON.stringify(response.data.report));
        populateStates(response.data);
        //alert(response.data);
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);            
            const nat=x['data']['accessToken'];
            const ufn=x['data']['userFullName'];
            const un=x['data']['userName'];
            const lt=x['data']['loginType'];
            //alert(nat);
            setAccessToken(nat);
            setRefreshFailed(false);  
                    
           
              if(lt.toLowerCase()==="newstudent")
                {
                      try{       
                        //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                        const response=await newStudentApi.post('/getCurrentRegistration',{id:1},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify(response.data));
                        //alert(JSON.stringify(response.data.report));
                        populateStates(response.data);
                        //alert(response.data);
                    }
                    catch(err)
                    {
                      //alert('refresh failed unusual'+JSON.stringify(err));
                      setAccessToken('');
                      setRefreshFailed(true);  
                    }
                  }
            }
            catch(err)
            {
              //alert('dd');
              setAccessToken(''); 
              setRefreshFailed(true);              
            }
        }         
         finally
            {
              ////setIsAppLoaded(true);
              //alert('app loaded'+accessToken);
            }
    }  
    fetchCurrent();
  },[]);

 

  useEffect( ()=>{
    loadOfferedCourses();
  },[selectedProgram,selectedSpecialisation]);
  // Handle dropdown change event
  function onCollegeChange(e)
  {
    setSelectedCollege(e.target.value);
    const college = colleges.find((col) => col.code == e.target.value); 
    //alert(JSON.stringify(college));alert(JSON.stringify(e.target.value));
    if(college && college.title)
    {
      setSelectedCollegeName(college.title||'');

    }
    else{

      setSelectedCollegeName('');
    }
  }

   // Handle dropdown change event
   function onProgChange(e)
   {
     setSelectedProgram(e.target.value);
     const pgo = programs.find((p) => p.code == e.target.value);
     if(pgo && pgo.title)
      {
        setSelectedProgramName(pgo.title||'');
  
      }
      else{
  
        setSelectedProgramName('');
      }
     setSelectedSpecialisation('');
     setSelectedCourses({courses:[]});     
   }
   // Handle dropdown change event
   const onSpecialisationChange = (event) => {
     setSelectedSpecialisation(event.target.value);     
     setSelectedCourses({courses:[]});
   };

   const onchangetxtAmount=(e)=>{
    const inputValue=e.target.value;
    // Regular expression to allow numbers and a single decimal point
    const regex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

    // If the input matches the regex, update the state, else ignore the input
    if (regex.test(inputValue) || inputValue === "") {
      setAmount(inputValue);
    }
  }


   function getDDLColleges()
   {    
     return (<select className="borderLess" value={selectedCollege} id='ddlProgram'
       onChange={(e)=>{onCollegeChange(e)}}
             >
       <option key={'none'} value={''}>
             {'SELECT ONE'}
       </option>
       {colleges.map((college) => (
           <option key={college.code} value={college.code}>
             {college.title}
           </option>
         ))}
       </select>);
   }
   function getDDLProgJSX()
   {
     return (<select className="borderLess" value={selectedProgram} id='ddlProgram'
       onChange={(e)=>{onProgChange(e)}}
             >
       <option key={'none'} value={''}>
             {'SELECT ONE'}
       </option>
       {programs.map((program) => (
           <option key={program.code} value={program.code}>
             {program.title}
           </option>
         ))}
       </select>);
   }
 
   function getDDLSpecialisationJSX()
   {
      // Find the program by code
   const px = programs.find((program) => program.code === selectedProgram);
   
   
     return (<select className="borderLess" value={selectedSpecialisation} id='ddlSpecialisation'
       onChange={(e)=>{onSpecialisationChange(e)}}
             >
       <option key={'none'} value={''}>
             {'SELECT ONE'}
       </option>
       {px && px.specialisation.map((s) => (
           <option key={s} value={s}>
             {s}
           </option>
         ))}
       </select>);
   }


   function getOfferedCourses()
   {
     return (
       <div className="verticaldiv centrediv fullWidth marginTopm">
          <OfferedCoursesS accessToken={accessToken} 
                 setAccessToken={setAccessToken}
                 setRefreshFailed={setRefreshFailed} session={'2024-ODD'} data={semOfferedCourses} setSelectedCourses={setSelectedCourses} selectedCourses={selectedCourses} semester={1} selectionType='local'/>
       </div>
     );
   }

   function addToImageList(selectedFiles)
  {
    const newImages=[...images,...selectedFiles];
      setImages(newImages);
      console.log(newImages.length);
  }
  async function deletePhoto(e,img)
  {
    e.preventDefault();
    try{       
      //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
      const response=await newStudentApi.post('/deleteImage',{img:img},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
      //alert(JSON.stringify(response.data));
      populateStates(response.data);
      //alert(response.data);
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);            
          const nat=x['data']['accessToken'];
          const ufn=x['data']['userFullName'];
          const un=x['data']['userName'];
          const lt=x['data']['loginType'];
          //alert(nat);
          setAccessToken(nat);
          setRefreshFailed(false);  
                  
         
            if(lt.toLowerCase()==="newstudent")
              {
                    try{       
                      //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                      const response=await newStudentApi.post('/deleteImage',{img:img},{headers:{'Authorization':`Bearer ${nat}`}});                            
                      //alert(JSON.stringify(response.data));
                      populateStates(response.data);
                      //alert(response.data);
                  }
                  catch(err)
                  {
                    //alert('refresh failed unusual'+JSON.stringify(err));
                    setAccessToken('');
                    setRefreshFailed(true);  
                  }
                }
          }
          catch(err)
          {
            //alert('dd');
            setAccessToken(''); 
            setRefreshFailed(true);              
          }
      }         
       finally
          {
            ////setIsAppLoaded(true);
            //alert('app loaded'+accessToken);
          }
  }

 

  function populateStates(rdata) {
    let data={};
    if(rdata && rdata.student)
    {
     data=rdata.student;  
    }
        setStudentName(data.name || '');
        setFatherName(data.fatherName || '');
        setMotherName(data.motherName || '');
        setMobileNumber(data.mobileNo || '');
        setMediumOfExam(data.mediumOfExam || '');
        setCaste(data.caste || '');
        setGender(data.gender || '');
        setReligion(data.religion || '');
        setSelectedProgram(data.programme_code || '');
        setSelectedProgramName(data.programme_name || '');
        setSelectedCollege(data.college || '');
        setSelectedCollegeName(data.collegeName || '');
        setSelectedSpecialisation(data.specialisation || '');
        setBankTransactionNumber(data.transactionNumber || '');
        setAmount(data.amount || '');
        setStoredImages(data.photos || []);
        setSelectedCourses({courses:data.courses || []});
        setImages([]);
        setUsername(data.email);
    }


  const btnSave=async()=>{
    let formData = new FormData(); 
    // Append scalar fields
    formData.append("studentName", studentName);
    formData.append("fatherName", fatherName);
    formData.append("motherName", motherName);
    formData.append("mobileNumber", mobileNumber);
    formData.append("mediumOfExam", mediumOfExam);
    formData.append("caste", caste);
    formData.append("gender", gender);
    formData.append("religion", religion);
    formData.append("selectedProgram", selectedProgram);
    formData.append("selectedCollege", selectedCollege);
    formData.append("selectedProgramName", selectedProgramName);
    formData.append("selectedCollegeName", selectedCollegeName);
    formData.append("selectedSpecialisation", selectedSpecialisation);
    formData.append("bankTransactionNumber", bankTransactionNumber);
    formData.append("amount", amount);

    // Append array of courses
    let scourses=[];
    if(selectedCourses && selectedCourses.courses)
    {
      scourses=selectedCourses.courses;
    }
    scourses.forEach((course, index) => {
      formData.append(`selectedCourses[${index}][code]`, course.code);
      formData.append(`selectedCourses[${index}][type]`, course.type);
  });



    Object.values(images).forEach(file=>{
      formData.append("images", file);
    });
    const config = {     
        headers: { 'content-type': 'multipart/form-data' }
    }

    try{
      //setIsAppLoaded(false);
      const response= await newStudentApi.post('/handleNewRegistration',formData,{headers:{'Authorization':`Bearer ${accessToken}`}});              
      //setIsAppLoaded(true);
      setRefreshFailed(false);
       populateStates(response.data);
       setPdfError([]);
       alert('SAVED');
      //alert(JSON.stringify(response.data));
      //setMode('view');
      }
      catch(err)
      {
        try{
          const config = { withCredentials: true };
          const x=await loginapi.get('/getAccessToken',config);
          //alert(JSON.stringify(x['data']['accessToken']));
          
          const nat=x['data']['accessToken'];
          setAccessToken(nat);
          setRefreshFailed(false);  
         
          //console.log(`ne at ${nat}`);
          //console.log(`Second at:${accessToken}`);
          try{       
            const response= await newStudentApi.post('/handleNewRegistration',formData,{headers:{'Authorization':`Bearer ${nat}`}});      
            //setIsAppLoaded(true);
            populateStates(response.data);
            setPdfError([]);
            alert('SAVED');
           }
           catch(err)
           {
            //alert('unusual error newposts.js');
            //setIsAppLoaded(true);
            setRefreshFailed(true);  
            //navigate('/');
           }
        }
        catch(err)
        {
          alert('failed');
          //setIsAppLoaded(true);
          setRefreshFailed(true);  
          //setError(JSON.stringify(err));
          //navigate('/');
        }

      }

  }

  
  function downloadFile(fname)
  {
    fetch(`${APISERVERURL}/pdf/${fname}`).then((response) => {
      response.blob().then((blob) => {         
          // Creating new object of PDF file
          const fileURL =
              window.URL.createObjectURL(blob);
               
          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = fname;
          alink.click();
          //setIsAppLoaded(true);
      });
    });
  }
const btnDownloadPdf=async (e)=>{
  e.preventDefault();
  try{
    //alert(img+" "+postId);
    //post request to deletePhoto wit {id,fileName}
    //setIsAppLoaded(false);
    const response= await newStudentApi.post('/getFormFillupPdf',{session:1},{headers:{'Authorization':`Bearer ${accessToken}`}});    
   
    if(response.data.fname)
    {
      //alert(response.data);
      downloadFile(response.data.fname);
    }
    else{
      setPdfError(response.data.error);
    }
  }
  catch(err)
  {
    try{
      const config = { withCredentials: true };
      const x=await loginapi.get('/getAccessToken',config);
      //alert(JSON.stringify(x['data']['accessToken']));
      
      const nat=x['data']['accessToken'];
      setAccessToken(nat);
      //console.log(`ne at ${nat}`);
      //console.log(`Second at:${accessToken}`);
      //alert('ok');
      try{       
        const response= await newStudentApi.post('/getFormFillupPdf',{session:1},{headers:{'Authorization':`Bearer ${nat}`}});    
        //alert(JSON.stringify(response.data));
        downloadFile(response.data);
       }
       catch(err)
       {
        alert('unusual error newposts.js');
        //setIsAppLoaded(true);
        //alert(JSON.stringify(err));
        //navigate('/');
       }
    }
    catch(err)
    {
      alert(JSON.stringify(err));
      //setIsAppLoaded(true);
      //setError(JSON.stringify(err));
      //navigate('/');
    }

  }
}


   let uimges=``;

    if(storedImages && storedImages.length!==0)
      {
          uimges=storedImages.map((image,index)=>{
          return      <div className='verticaldiv centrediv imgThumbHome2' key={index}>
                        <img  src={`${APISERVERURL}/img/${image}`} alt="placeholder" />
                        <button className='btn btn-danger loginButton' onClick={(e)=>{deletePhoto(e,image)}}>REMOVE IMAGE</button>
                        </div>
         })
      }
      else{
          uimges=<p>
          {"0 receipts uploaded!"}
          </p>
      }


   
    let errors={};  
    if(!studentName)
    {
     errors.studentName='Fill student name'; 
    }

    return (
      <main>
       <div className="fullwidth centrediv verticaldiv ">
        <div className="width1000 centrediv verticaldiv ">
        <label className="headertext">Registered Email:</label>
        <p className="infotext">{username}</p>


        <div className="inputGroup ">
        <label className="marginTop"  htmlFor='ddlProgram'>SELECT YOUR COLLEGE:</label>
        {  getDDLColleges()}
        </div>

        <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtStudentName">Student Name:</label>
        <input
          type="text"
          className="borderLess"
          id="txtStudentName"
          required
          value={studentName}
          onChange={(e) => setStudentName(e.target.value)}
        />
      </div>
      
      {/* Father Name */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtFatherName">Father Name:</label>
        <input
          type="text"
          className="borderLess"
          id="txtFatherName"
          required
          value={fatherName}
          onChange={(e) => setFatherName(e.target.value)}
        />
      </div>

      {/* Mother Name */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtMotherName">Mother Name:</label>
        <input
          type="text"
          className="borderLess"
          id="txtMotherName"
          required
          value={motherName}
          onChange={(e) => setMotherName(e.target.value)}
        />
      </div>

      {/* Mobile Number */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtMobileNumber">Mobile Number:</label>
        <input
          type="tel"
          className="borderLess"
          id="txtMobileNumber"
          required
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        />
      </div>

      {/* Medium of Exam */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtMediumOfExam">Medium of Exam:</label>
        <input
          type="text"
          className="borderLess"
          id="txtMediumOfExam"
          required
          value={mediumOfExam}
          onChange={(e) => setMediumOfExam(e.target.value)}
        />
      </div>

      {/* Caste */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtCaste">Caste:</label>
        <input
          type="text"
          className="borderLess"
          id="txtCaste"
          required
          value={caste}
          onChange={(e) => setCaste(e.target.value)}
        />
      </div>

      {/* gender */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtGender">Gender:</label>
        <input
          type="text"
          className="borderLess"
          id="txtGender"
          required
          value={gender}
          onChange={(e) => setGender(e.target.value)}
        />
      </div>

      {/* Religion */}
      <div className="inputGroup ">
        <label className="marginTop" htmlFor="txtReligion">Religion:</label>
        <input
          type="text"
          className="borderLess"
          id="txtReligion"
          required
          value={religion}
          onChange={(e) => setReligion(e.target.value)}
        />
      </div>
    
      <div className="inputGroup ">
        <label className="marginTop"  htmlFor='ddlProgram'>SELECT PROGRAM:</label>
        {getDDLProgJSX()}
      </div>
      
     
      <div className="inputGroup ">
        <label className="marginTop"  htmlFor='ddlSpecialisation'>SELECT SPECIALISATION:</label>
        {getDDLSpecialisationJSX()}       
      </div>
      
      <div className="inputGroup ">
        <label className="marginTop"  htmlFor='ddlSpecialisation'>SELECT COURSES:</label>
        { selectedSpecialisation!='' && selectedProgram!='' && getOfferedCourses()}  
        { (selectedSpecialisation=='' || selectedProgram=='') && <p>Select Program and specialisation first!</p>}     
      </div>
     
      <div className="inputGroup ">
        <label className="marginTop"  htmlFor='txtBankTxNo'>Bank transaction Number:</label>
        <input
          type="text"
          className="borderLess"
          id="txtBankTxNo"
          required
          value={bankTransactionNumber}
          onChange={(e) => setBankTransactionNumber(e.target.value)}
        />
      </div>
     
      <div className="inputGroup ">
        <label className="marginTop"  htmlFor='txtAmount'>Paid amount:</label>
        <input
          type="text"
          className="borderLess"
          id="txtAmount"
          required
          value={amount}
          onChange={onchangetxtAmount}
        />
      </div>
      <div className="inputGroup ">
        <label className="marginTop" > Uploaded Transaction receipt :</label>
        <div className="album">{uimges}</div>
      </div>
       

      <div className='inputGroup marginTop'>
          <label htmlFor="fuPhotos" className="custom-file-label-primary">
            Select Transaction receipt Images 
          </label>
          <input
            className="file-input-hidden"
            type="file"
            id="fuPhotos"
            name="uploadedPhotos"
            multiple
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => {
              addToImageList(e.target.files);
            }}
          />
      </div>
      <SelectedImages
      images={images}
      setImages={setImages}
      >
      </SelectedImages>  


      {pdfError && pdfError.length > 0 && pdfError.map((error, index) => (
        <p key={index} className="errorPara">{error}</p>
      ))}


      <div className="actionBar marginTop">
        
        <button className="btn btn-success loginButton" onClick={btnDownloadPdf}>DOWNLOAD PDF</button>
        <button className="btn btn-success loginButton" onClick={btnSave}>SAVE</button>
      </div>

      
     
        </div>
       </div>
      </main>
    )
  }
  
  export default NewStudent