import React from 'react';
import { useState,useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import api from './api/posts';
import loginapi from './api/login';
import SelectedCourseItem from './SelectedCourseItem';

const SelectedCourseList = ({setIsCourseSelected,setMode,session,accessToken,setAccessToken,setRefreshFailed}) => {
  const [isAppLoaded,setIsAppLoaded]=useState(false);
  const [selectedCourses,setSelectedCourses]=useState([]);


// Component for each semester with courses
const SemesterCourses = ({ semesterData }) => {
  let totalcredt=0;
  semesterData.courses.forEach((c)=>{
    totalcredt+=c.credit;
  })
  return (
    <div className="semestercourselistcontainer marginBottom">
      <div className='semesterheader'>
        <div className='semesterheadercol'>
           <div className='semesterheadercolkey'>
              SEMESTER
           </div>
           <div className='semesterheadercolval'>
              {semesterData.semester}
           </div>
        </div>
        <div className='semesterheadercol'>
           <div className='semesterheadercolkey'>
              COURSES
           </div>
           <div className='semesterheadercolval'>
              {semesterData.courses.length}
           </div>
        </div>
        <div className='semesterheadercol'>
           <div className='semesterheadercolkey'>
              CREDITS
           </div>
           <div className='semesterheadercolval'>
              {
                totalcredt
              }
           </div>
        </div>
      </div>
      {semesterData.courses.map((course, index) => (
        <SelectedCourseItem key={index} course={course} slno={index+1}/>
      ))}
    </div>
  );
};


  useEffect( ()=>{
    loadAllSelectedCourses();
  },[]);
  
  function onEditSelectionClick()
  {
    //alert(mode);
    setMode('select');
  }
 
    const loadAllSelectedCourses=async ()=>{
      //alert(JSON.stringify(loggedInStudent)+" selected sem "+selectedSemester);
       try{       
         //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
         const response=await api.post('/getAllSelectedCourses',{session:session},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
              //alert('ok');
              //alert(JSON.stringify( response.data)); 
          setSelectedCourses(response.data);
         //alert(response.data);
         }
         catch(err)
         {
           try{
             const config = { withCredentials: true };
             const x=await loginapi.get('/getAccessToken',config);            
             const nat=x['data']['accessToken'];
             const ufn=x['data']['userFullName'];
             const un=x['data']['userName'];
             const lt=x['data']['loginType'];
             //alert(nat);
             setAccessToken(nat);
             setRefreshFailed(false);  
                     
            
               if(lt.toLowerCase()==="student")
                 {
                       try{       
                         //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                         const response=await api.post('/getAllSelectedCourses',{session:session},{headers:{'Authorization':`Bearer ${nat}`}});                            
                          //alert('ok');
                          //alert(JSON.stringify( response.data)); 
                          setSelectedCourses(response.data);
                         //alert(response.data);
                     }
                     catch(err)
                     {
                       alert('refresh failed unusual'+ "line 50");
                       setAccessToken('');
                       setRefreshFailed(true);  
                     }
                   }
             }
             catch(err)
             {
               //alert('dd');
               setAccessToken(''); 
               setRefreshFailed(true);              
             }
         }         
          finally
             {
               setIsAppLoaded(true);
               //alert('app loaded'+accessToken);
             }
    }
  
  let totalCourses=0;
  let tx=``;
  if(selectedCourses)
  {
    selectedCourses.forEach((i)=>{
      if(i.courses)
      {
        totalCourses+=i.courses.length;
      }
    });
  }
  if(totalCourses===0)
  {
    tx=<div className='verticaldiv fullWidth centeredDiv paddiv'>
       <p>You have not selected any courses yet. Click below to select your courses.</p>
    
      <div className="selectCoursesActionCover marginTopm marginBottom">
        <button className="btn btn-success" onClick={(e)=>{onEditSelectionClick(e)}}>SELECT COURSES</button> 
      </div>
    
    </div>;
    setIsCourseSelected(false);
  }
  else
  {
    tx=
    <>    
      <div className='verticaldiv centrediv fullWidth'>
      <p className='marginTopm'>Your selected courses</p>
      {selectedCourses
        .filter((semesterData) => semesterData.courses.length > 0) // Only keep items with non-empty courses
        .map((semesterData, index) => (
          <SemesterCourses key={index} semesterData={semesterData} />
        ))}
      </div>

      <p className='paddiv'>You can change your selected courses by clicking below.</p>
    
      <div className="selectCoursesActionCover marginTopm marginBottom">
        <button className="btn btn-success" onClick={(e)=>{onEditSelectionClick(e)}}>CHANGE SELECTED COURSES</button> 
      </div>
    </>;
    setIsCourseSelected(true);
  }
  return (
    <div className="formfillupview verticaldiv centrediv width1000 roundeddiv">
      {tx}
    </div>
  );
};

export default SelectedCourseList;
