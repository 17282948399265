import React from 'react';
import {useState} from 'react';
import SelectedImages from './SelectedImages'
import api from './api/posts';
import loginapi from './api/login';

const PaymentDetailsUpload = ({isAppLoaded,setIsAppLoaded,setMode,session,accessToken,setAccessToken,setRefreshFailed}) => {
  const [images,setImages]=useState([]);
  const [txtBankTxNumber,setTxtBankTxNumber]=useState('');
  const [txtAmount,setTxtAmount]=useState('');
  const [selectedSemester, setSelectedSemester] = useState("");
   // Handle select change
   const handleSemesterChange = (event) => {
    setSelectedSemester(event.target.value);
  };
  const onchangeTxtBankTxNumber=(e)=>{
    setTxtBankTxNumber(e.target.value);
  }
  const onchangetxtAmount=(e)=>{
    const inputValue=e.target.value;
    // Regular expression to allow numbers and a single decimal point
    const regex = /^[+-]?(\d+(\.\d*)?|\.\d+)$/;

    // If the input matches the regex, update the state, else ignore the input
    if (regex.test(inputValue) || inputValue === "") {
      setTxtAmount(inputValue);
    }
  }
  const onCancelTxButton=(e)=>{
    setMode('view');
  }

  const saveTransaction=async (e)=>{
      let formData = new FormData();    //formdata object

      formData.append("txtBankTxNumber",txtBankTxNumber); 
      formData.append("txtAmount",txtAmount); 
      formData.append("forSemester",selectedSemester); 
      Object.values(images).forEach(file=>{
        formData.append("images", file);
      });
      const config = {     
          headers: { 'content-type': 'multipart/form-data' }
      }

      try{
        setIsAppLoaded(false);
        const response= await api.post('/createFormFillupTransactionRecord',formData,{headers:{'Authorization':`Bearer ${accessToken}`}});              
        setIsAppLoaded(true);
        setRefreshFailed(false);  
        setTxtBankTxNumber('');setTxtAmount('');
        setImages([]);
        alert(JSON.stringify(response.data));
        setMode('view');
        }
        catch(err)
        {
          try{
            const config = { withCredentials: true };
            const x=await loginapi.get('/getAccessToken',config);
            //alert(JSON.stringify(x['data']['accessToken']));
            
            const nat=x['data']['accessToken'];
            setAccessToken(nat);
            setRefreshFailed(false);  
           
            //console.log(`ne at ${nat}`);
            //console.log(`Second at:${accessToken}`);
            try{       
              const response= await api.post('/createFormFillupTransactionRecord',formData,{headers:{'Authorization':`Bearer ${nat}`}});      
              setIsAppLoaded(true);
              setTxtBankTxNumber('');setTxtAmount('');
              setImages([]);
              alert(JSON.stringify(response.data));
              setMode('view');
             }
             catch(err)
             {
              alert('unusual error newposts.js');
              setIsAppLoaded(true);
              setRefreshFailed(true);  
              //navigate('/');
             }
          }
          catch(err)
          {
            alert('failed');
            setIsAppLoaded(true);
            setRefreshFailed(true);  
            //setError(JSON.stringify(err));
            //navigate('/');
          }
  
        }
  }
  function addToImageList(selectedFiles)
  {
    const newImages=[...images,...selectedFiles];
    /*for(let i=0;i<selectedFiles.length;i++)
      {
        let j=0;
        for(j=0;j<images.length;j++)
          {
              if(images[j].name===selectedFiles[i].name)
                {
                  console.log(selectedFiles[i].name);
                  break;
                }
          }
        if(j===images.length)
          {
            newImages.push(selectedFiles[i]);
          }        
      }
          */
      setImages(newImages);
      console.log(newImages.length);
  }
  let canSave=false;
  if(txtBankTxNumber.length>0 && images.length>0 && txtAmount.length>0 && selectedSemester>0)
  {
    canSave=true;
  }
  return (
    <div className='width1000 borderdiv paddiv'>
      <div className='verticaldiv centrediv marginTop'><h4>UPLOAD PAYMENT RECEIPT</h4></div>
      <div className='marginTopm'>
            <label htmlFor="txtBankTxNumber">Bank Transaction Number:</label>
            <input id="txtBankTxNumber" className="borderLess transparent" type='text'  onChange={onchangeTxtBankTxNumber} value={txtBankTxNumber}></input>            
      </div>
      <div className='marginTopm'>
            <label htmlFor="txtAmount">Amount Paid:</label>
            <input id="txtAmount" className="borderLess transparent" type='text'  onChange={onchangetxtAmount} value={txtAmount}></input>            
      </div>
      <div className='marginTopm'>
      <label for="semester-select">For Semester:</label>
      <select className="borderLess transparent" id="semester-select" onChange={handleSemesterChange}>
        <option value="-1">Select Semester</option>
        <option value="1">Semester 1</option>
        <option value="2">Semester 2</option>
        <option value="3">Semester 3</option>
        <option value="4">Semester 4</option>
        <option value="5">Semester 5</option>
        <option value="6">Semester 6</option>
        <option value="7">Semester 7</option>
        <option value="8">Semester 8</option>
      </select>
      </div>
      <div className='marginTopm'>
          <label htmlFor="fuPhotos" className="custom-file-label">
            Select bank receipt 
          </label>
          <input
            className="file-input-hidden"
            type="file"
            id="fuPhotos"
            name="uploadedPhotos"
            multiple
            accept="image/png, image/jpg, image/jpeg"
            onChange={(e) => {
              addToImageList(e.target.files);
            }}
          />
      </div>
      <SelectedImages
      images={images}
      setImages={setImages}
      >
      </SelectedImages>
      <div className='actionBar  marginTopm marginBottom'>
      {<button className='btn btn-warning'  onClick={onCancelTxButton}>CANCEL</button>}
        {canSave && <button className='btn btn-primary'  onClick={saveTransaction}>SAVE</button>}
        {!canSave && <button className='btn btn-primary' disabled onClick={saveTransaction}>SAVE</button>}
       
      </div>
    </div>
  );

};

export default PaymentDetailsUpload;
