import React from 'react';
import GroupS from './GroupS';

const OfferedCoursesS = ({selectionType, accessToken,setAccessToken,setRefreshFailed,data,semester,session, selectedCourses,setSelectedCourses}) => {

  let x=``;
  if(data && data.offeredCourses && data.offeredCourses.groups && data.offeredCourses.groups.length>0)
  {
    x= <div className='verticaldiv centrediv width1000 marginTopm'>
    {data.offeredCourses.groups.map((group,index) => (
      <GroupS selectionType={selectionType} accessToken={accessToken} 
      setAccessToken={setAccessToken}
      setRefreshFailed={setRefreshFailed} session={session} key={group.id} group={group} slno={index+1} semester={semester} selectedCourses={selectedCourses} setSelectedCourses={setSelectedCourses} />
    ))}
    </div>
  }
  else{
    x='No GROUPS to display!';
  }

  return (
   x
  );

};

export default OfferedCoursesS;
