
import axios from './api/login';
import {useNavigate} from 'react-router-dom';
import {useState} from 'react'

import Spinner from 'react-bootstrap/Spinner';
function LoginUI({setAccessToken,setIsAuthenticated,setRefreshFailed,setGlobalLoginType}){
  const [userName,setUserName]=useState('');
  const [password,setPassword]=useState('');
  const navigate=useNavigate();
  const [isLoading,setIsloading]=useState(false);
  const [otpError,setOtpError]=useState('');
  const [loginType,setLoginType]=useState('student');
  const [showSendOTP,setShowSendOTP]=useState(true);
  async function onUserNameChange(e)
  {
    setUserName(e.target.value);
    if(password.length<=0)
    {
      setShowSendOTP(true);
    }
    setOtpError('');
  }
  async function onPasswordChange(e)
  {
    setPassword(e.target.value);
    if(e.target.value.length>0)
    {
      setShowSendOTP(false);
    }
    else{
      setShowSendOTP(true);
    }
    setOtpError('');
  }

  const handleLoginTypeChange = (event) => {
    setLoginType(event.target.value);
  };
  
  async function handleLogin(e)
    {
      e.preventDefault();
      setIsloading(true);
      try{
            const config = { withCredentials: true };
            const data=await axios.post('/login',{
            userName:userName,
            password:password, 
            loginType:loginType           
            },config);
            //alert(JSON.stringify(data['data']['accesstoken']));
            setAccessToken(data['data']['accesstoken']);
            setGlobalLoginType(data['data']['loginType']);
            setIsAuthenticated(true);
            setRefreshFailed(false);
            setUserName('');
            setPassword('');
            navigate('/');              
                       
      }
      catch(err)
      {
        //alert(err);
        setAccessToken('');
        setIsAuthenticated(false);
        setGlobalLoginType('');
        setRefreshFailed(true);
        alert('Login failed!');
      }
      finally
      {
        setIsloading(false);     
      }
    }

    async function handleCancel(e)  {
      e.preventDefault();
      setLoginType('student');
    }
  async function handleNewStudent(e)  {
    e.preventDefault();
    setLoginType('newstudent');
  }

 
  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      setOtpError('Please enter a valid email address.');
      return false;
    } else {
      setOtpError('');
      return true;
    }
  };
async function hangleSendOTP(e)
  {
    e.preventDefault();
   

    if(validateEmail(userName))
     {
      setIsloading(true);
      setOtpError('');
      
      try{
      
            const config = { withCredentials: true };
            const data=await axios.post('/login/sendOTP',{
            userName:userName,
            password:password
            },config);
            if(data.data.message)
            {
              setOtpError(`${data.data.message}`);  
            }
            else{
              //alert(JSON.stringify(data.data.message));
              setOtpError(`Oops something went wrong!`);  
            }
            
                      
      }
      catch(err)
      {
        alert(JSON.stringify(err));
        setOtpError(err&&err.response&&err.response.data&&err.response.data.error?err.response.data.error:'OOPS! something went wrong!');
        setAccessToken('');
        setIsAuthenticated(false);
        setRefreshFailed(true);
        //alert('Login failed!');
      }
      finally
      {
        setIsloading(false);     
      }
    }
  }  


  async function handleNewLogin(e)  {
    e.preventDefault();
    setIsloading(true);
    try{
          const config = { withCredentials: true };
          const data=await axios.post('/login/handleNewLogin',{
          userName:userName,
          password:password,         
          },config);
          //alert(JSON.stringify(data['data']['accesstoken']));
          setAccessToken(data['data']['accesstoken']);
          setGlobalLoginType(data['data']['loginType']);
          setIsAuthenticated(true);
          setRefreshFailed(false);
          setUserName('');
          setPassword('');
          navigate('/newStudent');              
          //alert('navigate to new registration form');
                     
    }
    catch(err)
    {
      //alert(err);
      setAccessToken('');
      setIsAuthenticated(false);
      setGlobalLoginType('');
      setRefreshFailed(true);
      alert('Login failed!');
    }
    finally
    {
      setIsloading(false);     
    }
  }


    return (  
      <main>
        <form className="loginForm">
          {loginType !== 'newstudent' &&
            <div className='loginTypeDiv'>
                <label className='radiolabel'>
                  <input
                    type="radio"
                    value="student"
                    checked={loginType === 'student'}
                    onChange={handleLoginTypeChange}
                  />
                  <div className={loginType === 'student' ? 'radiooptiondiv selectedloginoption' : 'radiooptiondiv'}>STUDENT</div>
                </label>
                <label className='radiolabel'>
                  <input
                    type="radio"
                    value="admin"
                    checked={loginType === 'admin'}
                    onChange={handleLoginTypeChange}
                  />
                   <div className={loginType === 'admin' ? 'radiooptiondiv selectedloginoption' : 'radiooptiondiv'}>ADMIN</div>
                </label>
            </div>
          }
          
          {loginType === 'admin' && <div className="loginDiv marginTopm">
            <label>USER NAME:</label>
            <input className="borderLess transparent" type='text'  onChange={(e)=>{setUserName(e.target.value)}} value={userName}></input>
            <label className="marginTop">PASSWORD</label>
            <input className="borderLess transparent" type='password' onChange={(e)=>{setPassword(e.target.value)}} value={password}></input>
            <div className="marginTop fullWidth loginButtonyNav"><button className="btn btn-primary loginButton" name='btnLogin' onClick={handleLogin}>ADMIN LOGIN</button></div>
          </div>}
          {loginType === 'student' && <div className="loginDiv marginTopm">
            <label>ROLL NO:</label>
            <input className="borderLess transparent" type='text' placeholder="type your roll number" onChange={(e)=>{setUserName(e.target.value)}} value={userName}></input>
            <label className="marginTop">PASSWORD</label>
            <input className="borderLess transparent" type='password' placeholder="See hint below" onChange={(e)=>{setPassword(e.target.value)}} value={password}></input>
            <div className="marginTop fullWidth actionpanel">
            <button className="btn btn-success loginButton" name='btnLogin' onClick={handleNewStudent}>NEW STUDENT</button>
              <button className="btn btn-primary loginButton" name='btnLogin' onClick={handleLogin}>LOGIN</button>
            </div>
            <div className="marginTop fullWidth">
              <p className='parapasswordhint'>If you are a PG First Year Student of BATCH-2024, click on NEW STUDENT</p>
              <p className='parapasswordhint'>Password Hint: your rollno followed by your name in CAPITAL LETTER. No Space. For ex. if your rollno is <span>UA190013</span> and Name is <span>Pallabi Bora</span>, your password will be <span>UA190013PALLABIBORA</span> </p>
            </div>

          </div>}

          {loginType === 'newstudent' && <div className="loginDiv marginTopm">
            <label>Your Email ID:</label>
            <input className="borderLess transparent" type='text' placeholder="type your email id. For example, johndoe@gmail.com" onChange={(e)=>{onUserNameChange(e)}} value={userName}></input>
            <label className="marginTop">Received OTP</label>
            <input className="borderLess transparent" type='password' placeholder="Enter the OTP you received over email" onChange={(e)=>{onPasswordChange(e)}} value={password}></input>
            <div className="marginTop fullWidth actionpanel">
            <button className="btn btn-success loginButton"  onClick={handleCancel}>CANCEL</button>
            {showSendOTP &&    <button className="btn btn-warning loginButton"  onClick={hangleSendOTP}>SEND OTP</button>} 
            {!showSendOTP &&    <button className="btn btn-warning loginButton" disabled onClick={hangleSendOTP}>SEND OTP</button>} 
              <button className="btn btn-primary loginButton"  onClick={handleNewLogin}>LOGIN</button>
            </div>
            <div className='verticalDiv centeredDiv fullWidth'>{otpError &&
                <p>{otpError}</p>
                }</div>
            <div className="marginTop fullWidth centrediv"><p className="fullWidth verticaldiv centrediv">REGISTRATION IS ONLY FOR 1ST SEMESTER STUDENTS</p></div>
          </div>}

        </form>

        {isLoading && 
        <div className='spinneroverlay'>
          <div className='spinnerbody'>
            <Spinner animation="border" variant="info" />
            <p>Please wait. It might take a few seconds.</p>
          </div>           
        </div>}
      </main> 

       );
}
export default LoginUI;
