import React from 'react';
import {useState,useEffect} from 'react';
import api from './api/posts';
import loginapi from './api/login';
import Transaction from './Transaction';

const UploadedTransactions = ({setCurrentTransactionId,setIsPayementUploaded,isAppLoaded,setIsAppLoaded,mode,setMode,session,accessToken,setAccessToken,setRefreshFailed}) => {
  const [transactions,setTransactions]=useState([]);
  const btnUploadPaymentReceipt=(e)=>{
    setMode('UPLOADPAYMENTNEW');
  }
  const loadTransactions=async ()=>{
    setIsAppLoaded(false);
    //alert(JSON.stringify(loggedInStudent)+" selected sem "+selectedSemester);
     try{       
       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
       const response=await api.post('/getStudentFormFillupTransactions',{session:session},{headers:{'Authorization':`Bearer ${accessToken}`}});                            
       //alert(JSON.stringify( response.data)); 
       setTransactions(response.data);
       //alert(response.data);
       }
       catch(err)
       {
         try{
           const config = { withCredentials: true };
           const x=await loginapi.get('/getAccessToken',config);            
           const nat=x['data']['accessToken'];
           const ufn=x['data']['userFullName'];
           const un=x['data']['userName'];
           const lt=x['data']['loginType'];
           //alert(nat);
           setAccessToken(nat);
           setRefreshFailed(false);  
                   
          
             if(lt.toLowerCase()==="student")
               {
                     try{       
                       //const response=await api.get('/stats',{headers:{'Authorization':`Bearer ${accessToken}`}});
                       const response=await api.post('/getStudentFormFillupTransactions',{session:session},{headers:{'Authorization':`Bearer ${nat}`}});                            
                        //alert(JSON.stringify( response.data)); 
                        setTransactions(response.data);
                       //alert(response.data);
                   }
                   catch(err)
                   {
                     alert('refresh failed unusual'+ "line 50");
                     setAccessToken('');
                     setRefreshFailed(true);  
                   }
                 }
           }
           catch(err)
           {
             //alert('dd');
             setAccessToken(''); 
             setRefreshFailed(true);              
           }
       }         
        finally
           {
             setIsAppLoaded(true);
             //alert('app loaded'+accessToken);
           }
  }


  useEffect( ()=>{
      loadTransactions();
  },[mode]);

  let xx=``;
  if(transactions && transactions.length>0)
  {
    setIsPayementUploaded(true);
    xx=<div className='verticaldiv centeredDiv width1000 paymentdetailsdiv paddiv marginTop roundedCorner'>
       <div className='fullWidth centeredDiv'>
        <p>Here are your uploaded payment details. Click on them to change the details or to delete them. You can add more by clicking on <button className='link-button' onClick={btnUploadPaymentReceipt}>ADD PAYMENT RECEIPT</button>.</p>
       </div>
          <div className='album'>              
          {    
          transactions.map((transaction)=>{
              return (
                  <Transaction setCurrentTransactionId={setCurrentTransactionId} key={transaction.id} transaction={transaction} setMode={setMode}></Transaction>
              );
          })
          }  
          </div>
        </div>
  }
  else{
        setIsPayementUploaded(false);
        xx=<div className='verticaldiv centeredDiv width1000 paymentdetailsdiv paddiv marginTop roundedCorner'>
        <p>You have not yet uploaded any payment receipt. Click on <button className='link-button' onClick={btnUploadPaymentReceipt}>ADD PAYMENT RECEIPT</button> to upload the receipt(s).</p>;
        </div>
  }
  return (
    
      xx
      
  );

};

export default UploadedTransactions;
