import {Link} from 'react-router-dom';
import { APISERVERURL } from './APIServerURL';

const Transaction=({transaction,setMode,setCurrentTransactionId})=>{
    let to=`/transaction/${transaction._id}`;  
    const onEditTxDetailsClick=(e)=>{
        setMode('editTransactionDetails');
        setCurrentTransactionId(transaction);
    }
    const imgName=(transaction.photos && transaction.photos.length>0)?transaction.photos[0]:"default.png";
    return( 
        <div className='imgThumbHome overLay'>                          
                        <img  src={`${APISERVERURL}/img/${imgName}`} alt="placeholder" />
                        <button className='btn btn-success btnEditTxDetails' onClick={onEditTxDetailsClick}>EDIT</button>
                        <div className='imgInfo'>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>Transaction Number:</p>
                                <p className='infoText gridInfo'>
                                    {transaction.transactionNumber}
                                </p>
                            </div>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>Amount:</p>
                                <p className='infoText gridInfo'>
                                    { transaction.amount}
                                </p>
                            </div>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>For Semester:</p>
                                <p className='infoText gridInfo'>
                                    { transaction.forSemester}
                                </p>
                            </div>
                            <div className="infoGroup">
                                <p className='headerText gridLabel'>Date:</p>
                                <p className='infoText gridInfo'>
                                    { transaction.ondate}
                                </p>
                            </div>
                            <div className="infoGroupSingle">
                                <p className='infoText'>
                                    {
                                        transaction.photos.length+" PHOTOS"
                                    }           
                                </p>
                            </div>
                        </div>
                       
           
        </div>  
    )
}
export default Transaction