import { Link } from 'react-router-dom';
function HomeAdmin({}) {

  let e=undefined;
   
  return (
    <main>
      <article className='newPost'>
      <div className="fullWidth">
          <div className="fullWidth featureContainer ">
                <button className="featureButton"><Link className='navLinkBlack' to="/offeredcourses">SET OFFERED COURSES</Link></button> 
                <button className="featureButton"><Link className='navLinkBlack' to="/viewFormFillup">FORM FILLUP DETAILS</Link></button> 
                <button className="featureButton"><Link className='navLinkBlack' to="/editStudentName">STUDENT DETAILS</Link></button> 
                <button className="featureButton"><Link className='navLinkBlack' to="/getEnrolledStudents">ATTENDANCE SHEET</Link></button> 
          </div>
      </div>
      </article>
    </main>
    )
  }
  
  export default HomeAdmin