import { Link } from 'react-router-dom';
function NavNewStudent() {
    return (
      <nav className="nav">
        <div className="search-area">
                 
        </div>
        <div className="links-area">
          <ul>
              <li><Link className='navLink' to="/newStudent">HOME</Link> </li>
              <li><Link className='navLink' to="/moreoptions">MORE</Link></li>
          </ul>
        </div>
      </nav>
    )
  }
  
  export default NavNewStudent